<template>
  <div class="phone-recharge-one">
    <div class="top-tips">
      <p>开通鲸选师省钱卡后，方可获取话费券资格</p>
    </div>
    <div class="content">
      <div class="phone-coupon">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_coupon.png">
        <div class="coupon-text">
          <p class="amount">100元话费充值券</p>
          <p class="count">仅剩<span>5</span>份 先到先得</p>
          <p class="way">使用手机号登录，立即领取100元话费券</p>
        </div>
      </div>
      <div class="fixed-button">
        <img class="rules" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_rule1.png" @click="handleJumpApp('rule')">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_service.png"
             @click="handleJumpApp('service')">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_report.png" @click="handleJumpApp('report')">
      </div>
      <van-field class="phone-input" type="number" pattern="\d*" v-model="phone" placeholder="请输入您的手机号码"
                 left-icon="phone-o"/>
      <van-button class="button" @click="go">立即领取</van-button>
    </div>
    <van-dialog v-model="rulesVisible" :showConfirmButton="false" :closeOnClickOverlay="true"
                style="background-color: transparent;">
      <div v-if="dialogType == 'rule'" class="wrapper">
        <p>活动规则</p>
        <div class="content">
          <p>
            以下权益专属优惠，仅在支付会员费成为鲸选省钱卡会员后方可享有，均在鲸选师APP内使用：
            <br/>
            <br/>
            1、费用说明：支付会员费后即可成为鲸选省钱卡会员，一次办理，终身免年费，我们将不再收取任何费用。
            <br/>
            <br/>
            2、参与活动的新用户成功办理鲸选省钱卡会员后，将获得总价值100元的话费券，本话费券用于三网客户充值服务，可在全国范围（港澳台除外）使用，更多规则详见鲸选师APP内活动规则；
            <br/>
            <br/>
            3、参与活动的新用户成功办理鲸选省钱卡会员后，将获得总价值100元的话费券，共计20张价值5元的话费返利卡，每次仅限使用一张话费券，话费券使用规则详见鲸选师APP内活动规则；
            <br/>
            <br/>
            4、话费券使用方法：登录鲸选师APP--点击【我的页面】中的话费券--点击话费券跳转至话费充值平台进行充值即可；
            <br/>
            <br/>
            5、返利金在用户确认收货后次月25号，将返还到用户的APP账号余额，可随时申请提现至微信钱包，账号余额查询方式：鲸选师APP--我的--我的钱包。
            <br/>
            <br/>
            6、话费充值因涉及第三方通信运营商，以及网络系统的稳定性等原因，充值话费到账时间可能存在延迟，尽请谅解，您可咨询客服或关注“鲸选师”公众号以及微信小程序，我们将及时回复处理。
            服务时间（工作日）：9:00-24:00。
            <br/>
            <br/>
            7、如何退费：①可登录鲸选师APP→我的→联系客服→申请退款（联系在线客服），注册会员后1个月内未下载APP领券话费券的会员用户，随时可申请退款，发起申请后，我们会在3个工作日内完成退款。登录APP且领取话费券的会员，无法退款，购买会员前请您再次确认。②当您购买我们的鲸选省钱卡会员后，若您选择申请退款，退款成功后，您的话费券领取资格将失效，请知悉。
            <br/>
            <br/>
            8、在法律允许的范围内，根据活动的进展情况，鲸选师可能对活动的规则/条款作出适当修改/调整。
          </p>
        </div>
        <van-button @click="handleCloseOverlay">立即办理</van-button>
        <!--        <div class="close">-->
        <!--          <img src="https://cdn.cailu88.com/jingxuanshi/close%402x.png" @click="handleCloseOverlay"/>-->
        <!--        </div>-->
      </div>
      <div v-else-if="dialogType == 'service'" class="wrapper">
        <div class="wx-qrcode">
          <img src="https://cdn.cailu88.com/jingxuanshi/kf_official.png"/>
        </div>
        <div class="service">
          <p class="service-time">客服服务时间：9:00-24:00</p>
          <p class="service-info">打开微信，扫描二维码添加客服</p>
        </div>
        <van-button @click="handleSaveWX">保存二维码</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import baseUrl from '@/utils/baseUrl'
import { Field, Button, Dialog, Toast } from 'vant'

Vue.use(Field)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Toast)
const api = require('@/utils/api').api

export default {
  name: 'phoneRechargeOne',
  data () {
    return {
      // 手机号
      phone: '',
      // 活动规则
      rulesVisible: false,
      // 弹窗类型
      dialogType: '',
      pi: '',
      attach: '',
    }
  },
  created () {
    this.pi = this.$route.query.pi
    this.attach = this.$route.query.bc_tag ? this.$route.query.bc_tag : ''
    localStorage.setItem('attach', this.attach)
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    this.handleGetBaseInfo()
  },
  methods: {
    /*
    跳转jxsAPP内部事件
     */
    handleJumpApp (target) {
      if (target == 'report') {
        this.$router.push({
          name: 'putReport',
          query: {
            phone: this.phone,
            pi: this.pi,
            attach: this.attach,
            template: 1
          }
        })
      }
      this.dialogType = target
      this.rulesVisible = true
    },

    /*
    关闭弹窗
    */
    handleCloseOverlay () {
      this.rulesVisible = false
    },

    /*
    保存wx二维码
    */
    handleSaveWX () {
      let picUrl = 'https://cdn.cailu88.com/jingxuanshi/kf_official.png'
      let link = document.createElement('a')
      link.download = name || 'pic'
      link.href = picUrl
      link.click()
      this.rulesVisible = false
    },

    /*
    忍痛拒绝
    */
    handleRefuseOpen () {
      this.rulesVisible = false
    },
    /*
    获取投放基本信息
     */
    handleGetBaseInfo () {
      this.$axios({
        url: api.baseInfo,
        method: 'get',
        params: {
          pushTemplateId: this.pi,
          bizType: 1,
          template: 1
        }
      }).then(res => {
      }).catch(err => {
        console.info(err)
      })
    },

    /*
    立即领取 点击事件
     */
    go () {
      if (this.phone.split('').length != 11) {
        Toast.fail('请输入正确的手机号')
        return
      }
      this.$axios({
        url: api.privilegeCheck,
        method: 'get',
        params: {
          phone: this.phone,
          pushTemplateId: this.pi,
          redirectUrl: `${baseUrl.pageUrl}/jingxuanvip/pay`,
          bizType: 1,
          template: 1
        }
      }).then(res => {
        // recharged true 使用过特权, false 未使用特权
        this.$router.push({
          name: 'phoneOnePay',
          query: {
            phone: this.phone,
            recharged: res.data.data.recharged,
            pi: this.pi,
            wxRedirectUrl: res.data.data.wxRedirectUrl
          },
        })
      }).catch(err => {
        console.info(err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.phone-recharge-one {
  min-height: 100vh;
  background: #33B870;

  .top-tips {
    height: 30px;
    background: linear-gradient(90deg, #FFEBB0 0%, #F3A869 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 15px;
      color: #7F694E;
      line-height: 21px;
    }
  }

  .content {
    padding-top: 16px;
    text-align: center;

    .phone-coupon {
      position: relative;
      text-align: center;

      & > img {
        width: 350px;
      }

      .coupon-text {
        position: absolute;
        top: 43px;
        left: 40px;

        .amount {
          font-size: 38px;
          font-weight: bold;
          color: #33B870;
          line-height: 53px;
        }

        .count {
          margin-top: 44px;
          margin-bottom: 6px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;

          & > span {
            color: #E44A59;
          }
        }

        .way {
          font-size: 15px;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
        }
      }
    }

    .fixed-button {
      position: fixed;
      right: 0;
      top: 87px;
      display: flex;
      flex-direction: column;

      .rules {
        position: absolute;
        top: -48px;
        right: 0;
        width: 54px;
      }

      img {
        width: 34px;
        margin-bottom: 3px;
      }
    }

    .phone-input {
      margin: 24px auto 16px;
      width: 325px;
      background: #FFFFFF;
      border-radius: 10px;
    }

    .button {
      width: 325px;
      height: 55px;
      background: linear-gradient(146deg, #FFE687 0%, #FFC667 100%);
      border-radius: 28px;
      border: none;
      font-size: 20px;
      font-weight: bold;
      color: #7F694E;
      line-height: 28px;
    }
  }

  .wrapper {
    position: relative;
    width: 320px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;

    & > p {
      display: inline-block;
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      color: #7F694E;
      line-height: 28px;
    }

    .content {
      margin: 0 auto;
      width: 272px;
      height: 313px;
      font-size: 15px;
      font-weight: 400;
      overflow-y: scroll;
      color: #333333;
      line-height: 25px;

      p {
        text-align: left;
      }
    }

    .van-button {
      margin-top: 10px;
      margin-bottom: 15px;
      width: 280px;
      height: 50px;
      background: linear-gradient(90deg, #FFE687 0%, #FFC667 100%);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #7F694E;
      line-height: 25px;
    }

    .wx-qrcode {
      display: inline-block;
      margin-top: 35px;
      & > img{
        width: 198px;
        height: 198px;
      }
    }

    .service {
      margin-top: 8px;
      margin-bottom: 14px;

      .service-info {
        font-size: 18px;
        font-weight: bold;
        color: #7F694E;
        line-height: 25px;
        margin-top: 8px;
      }

      .service-time {
        font-size: 15px;
        font-weight: 400;
        color: #7F694E;
        line-height: 21px;
      }
    }

    .quick-info {
      display: inline-block;
      margin-top: 20px;

      .info-1,
      .info-2 {
        display: flex;
        align-items: center;
        justify-content: center;

        p:first-child {
          font-size: 20px;
          font-weight: bold;
          color: #7F694E;
          line-height: 28px;
        }

        p:last-child {
          margin-left: 4px;
          font-size: 26px;
          font-weight: bold;
          color: #E44A59;
          line-height: 37px;
        }
      }

      .info-3 {
        margin-top: 12px;
        font-size: 15px;
        font-weight: 400;
        color: #7F694E;
        line-height: 21px;
      }
    }

    .refuse {
      margin-bottom: 14px;

      p {
        font-size: 15px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
      }
    }

    //.close{
    //  margin-top: 34px;
    //  text-align: center;
    //  & > img{
    //    width: 32px;
    //  }
    //}
  }

}
</style>
